import axios, { CancelTokenSource } from "axios";
import moment from "moment-timezone";
import { defineStore } from "pinia";

import { OrganizationsApi } from "@/api/organizations";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { IThemeColors } from "@/pinia-store/app";

export interface IOrganization {
  id?: string;
  name: string;
  type: string;
  size: number;
  url: string;
  component?: string;
  componentId?: string;
  appName: string;
  logoUrl: string;
  logoReducedUrl: string;
  logoExpandedUrl: string;
  themeColors: IThemeColors;
  brandName: string;
  brandDomain: string;
  brandAddress: string;
  contactPhoneNumber: string;
}

interface IOrganizationStore {
  organizations: IOrganization[];
  form: Partial<IOrganization>;
  isLoading: boolean;
  isSaving: boolean;
  cancelRequestToken?: CancelTokenSource | null;
  selectedOrganization?: IOrganization | null;
  schedule: Record<any, any>;
  organization: Partial<IOrganization>;
}

const demoEvents = [
  {
    name: "Clinic",
    status: "pending",
    start: moment().add(2, "hours").format(),
    end: moment().add(5, "hours").format(),
  },
];

export const useOrganizationStore = defineStore({
  id: "organization",
  state: (): IOrganizationStore => ({
    organization: {},
    organizations: [],
    isSaving: false,
    schedule: {
      slots: demoEvents,
    },
    selectedOrganization: null,
    form: {
      logoUrl: "",
      themeColors: {
        icon: "",
        success: "",
        error: "",
        errorlight1: "",
        primarylight3: "",
        primarylight2: "",
        primarylight1: "",
        primarylight: "",
        primary: "",
        primaryblack: "",
        primaryblack2: "",
        primaryblack3: "",
        primaryblack4: "",
        primaryblack5: "",
      },
    },
    cancelRequestToken: null,
    isLoading: false,
  }),
  actions: {
    setOrganizations(payload: IOrganization[]) {
      this.organizations = payload;
    },
    setOrganization(payload: IOrganization) {
      this.organization = payload;
    },
    changeFormField(value: any, field: string) {
      console.log(value, field);
      this.form[field] = value;
    },
    setForm(item: IOrganization) {
      console.log("set form", item);
      this.form = { ...item, themeColors: item.themeColors || {} };
    },
    async saveForm() {
      this.isSaving = true;
      const isUpdate = Boolean(this.form.id);
      try {
        if (this.form.id) this.form = await OrganizationsApi.update(this.form);
        else this.form = await OrganizationsApi.create(this.form);
        snackBarEventBus.$emit(snackBarEventName, {
          message: isUpdate ? "Organization updated" : "Organization created",
          type: "success",
        });
      } catch (error) {
        console.error(error);
        snackBarEventBus.$emit(snackBarEventName, {
          message: "An error occurred",
          type: "error",
        });
      }
      this.isSaving = false;
    },
    changeFormThemeColorsField(value: any, field: string) {
      if (!this.form.themeColors) this.form.themeColors = {};
      this.form.themeColors[field] = value;
    },
    async getOrganizations() {
      const cancelRequestToken = this.cancelRequestToken;
      if (cancelRequestToken) cancelRequestToken.cancel && cancelRequestToken.cancel();
      this.cancelRequestToken = axios.CancelToken.source();
      this.isLoading = true;
      try {
        this.organizations = await OrganizationsApi.getAll();
        this.cancelRequestToken = null;
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;
    },
    async getOrganization(organizationId: string): Promise<void> {
      this.isLoading = true;
      try {
        this.organization = await OrganizationsApi.find(organizationId);
      } catch (error) {
        console.error(error);
      }

      this.isLoading = false;
    },
  },
});
