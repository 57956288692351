<template>
  <PageContainer>
    <template v-slot:header>
      <v-col>
        <span class="heading-2">{{ organization.appName || organization.brandName }} Schedule </span>
      </v-col>
      <v-col class="justify-end d-flex gap-1">
        <IconicButton left-icon="mdi-plus" lite text="Add schedule" @onClick="add" />
        <IconicButton left-icon="mdi-plus" lite text="Add time slot" @onClick="addSlot" />
      </v-col>
    </template>
    <div class="organization-item-container">
      <v-progress-linear v-if="isLoading" indeterminate />
      <div class="calendar_header">
        <OutlinedButton text="Today" class="calendar_header_today-btn" @onClick="moveToday" />
        <v-btn
          :min-width="30"
          :ripple="false"
          :width="30"
          class="calendar_header_btn__switch outlined-btn outlined-btn__medium"
          outlined
          @click="movePrev"
          ><img alt="Prev" class="calendar_header_icon__rotate" src="@/assets/icons/arrow.svg" />
        </v-btn>
        <v-btn
          :min-width="30"
          :ripple="false"
          :width="30"
          class="calendar_header_btn__switch outlined-btn outlined-btn__medium"
          depressed
          outlined
          @click="moveNext"
        >
          <img alt="Next" src="@/assets/icons/arrow.svg" />
        </v-btn>
        <div class="gap-1">
          <v-btn
            v-for="item in calendarValues"
            :key="item.value"
            :color="item.value === calendarValue ? 'primary' : ''"
            :ripple="false"
            class="calendar_header_btn__switch outlined-btn outlined-btn__medium"
            depressed
            outlined
            @click="changeCalendarValue(item.value)"
          >
            {{ item.text }}
          </v-btn>
        </div>
      </div>
      <v-row>
        <v-col md="4" order="2">
          <v-card class="mx-auto">
            <v-list>
              <v-list-group v-model="openedSchedules" no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>Schedules</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item v-for="item in schedules" :key="item.id" class="pl-2 justify-space-between">
                  <v-list-item-action>
                    <v-switch
                      :value="selectedSchedulesIds.includes(item.id)"
                      class="switcher"
                      title="Show on calendar"
                      @change="toggleSchedule(item)"
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ toScheduleName(item) }}</v-list-item-title>
                    <v-list-item-subtitle v-if="item.comment?.length">{{ item.comment }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="d-flex flex-row gap-1">
                    <v-btn icon @click="() => editSchedule(item)">
                      <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn color="error" icon>
                      <v-icon>mdi-trash-can-outline</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-card>
        </v-col>
        <v-col md="8" order="1">
          <v-sheet height="700">
            <v-calendar
              ref="calendar"
              :event-color="getEventColor"
              :events="events"
              :type="calendarValue"
              :start="currentDate"
              @click:time="selectCalendarTime"
              @click:event="selectCalendarEvent"
              @click:more="onMoreClick"
              @click:date="onCalendarDateClick"
            >
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </div>
    <ScheduleEditModal />
    <SlotEditModal @changed="reloadSlots" />
  </PageContainer>
</template>
<script>
import moment from "moment-timezone";
import { mapActions, mapState, mapWritableState } from "pinia";

import { OrganizationsApi } from "@/api/organizations";
import { timeDisplayFormat } from "@/components/Clinicians/constants";
import IconicButton from "@/components/uikit/IconicButton.vue";
import OutlinedButton from "@/components/uikit/OutlinedButton.vue";
import PageContainer from "@/components/uikit/PageContainer.vue";
import { useAuthStore } from "@/pinia-store/auth";
import { useOrganizationStore } from "@/pinia-store/organization";
import { useScheduleStore } from "@/pinia-store/schedule";
import { defaultFormSlot, SlotAppointmentTypeEnum, useSlotsStore } from "@/pinia-store/slots";
import { routesEnum } from "@/types/Routes.enum";
import { calendarValues } from "@/utils/constants";
import { scrollToTop } from "@/utils/scrollToBottom";
import ScheduleEditModal from "@/views/Owner/Organizations/OrganizationSchedule/EditModal.vue";
import SlotEditModal from "@/views/Owner/Organizations/OrganizationSchedule/SlotEditModal.vue";

export default {
  name: "OrganizationScheduleIndex",
  data() {
    return {
      ready: false,
      isLoading: false,
      calendarValue: "month",
      currentDate: new Date(),
      openedSchedules: true,
      slots: [],
    };
  },
  components: { OutlinedButton, SlotEditModal, ScheduleEditModal, IconicButton, PageContainer },
  computed: {
    ...mapState(useAuthStore, ["timeZone"]),
    ...mapWritableState(useScheduleStore, ["schedules", "selectedSchedulesIds"]),
    ...mapState(useOrganizationStore, ["organizations", "organization", "schedule"]),
    ...mapWritableState(useScheduleStore, ["editDialog"]),
    ...mapState(useSlotsStore, ["events"]),
    calendarValues() {
      return calendarValues;
    },
    nowY() {
      console.log("nowY", this.cal);
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
  },
  methods: {
    ...mapActions(useOrganizationStore, ["setOrganization"]),
    ...mapActions(useScheduleStore, ["setForm", "clearForm", "fetchOrganizationSchedules"]),
    ...mapActions(useSlotsStore, { setSlotForm: "setForm" }),
    ...mapActions(useSlotsStore, ["toggleEditDialogSlot", "fetchSlotsBySchedules"]),
    movePrev() {
      if (this.calendarValue === "category" || this.calendarValue === "day") {
        const newDate = moment(this.currentDate).subtract(1, "days");
        this.currentDate = new Date(newDate);
      }
      if (this.calendarValue === "week") {
        const newDate = moment(this.currentDate).subtract(7, "days");
        this.currentDate = new Date(newDate);
      }
      if (this.calendarValue === "month") {
        const newDate = moment(this.currentDate).subtract(1, "M");
        this.currentDate = new Date(newDate);
      }
    },
    moveNext() {
      if (this.calendarValue === "category" || this.calendarValue === "day") {
        const newDate = moment(this.currentDate).add(1, "days");
        this.currentDate = new Date(newDate);
      }
      if (this.calendarValue === "week") {
        const newDate = moment(this.currentDate).add(7, "days");
        this.currentDate = new Date(newDate);
      }
      if (this.calendarValue === "month") {
        const newDate = moment(this.currentDate).add(1, "M");
        this.currentDate = new Date(newDate);
      }
    },
    moveToday() {
      this.currentDate = new Date();
    },
    async toggleSchedule(item) {
      this.isLoading = true;
      if (this.selectedSchedulesIds.includes(item.id)) {
        this.selectedSchedulesIds = this.selectedSchedulesIds.filter((id) => id !== item.id);
      } else {
        this.selectedSchedulesIds.push(item.id);
      }
      await this.fetchSlotsBySchedules({ scheduleIds: this.selectedSchedulesIds });
      this.isLoading = false;
    },
    makeRoundTime(start, end, small = false) {
      const startDate = this.$moment.tz(start, this.timeZone);
      const endDate = this.$moment.tz(end, this.timeZone);
      return `${startDate.format("HH:mm")} - ${endDate.format("HH:mm")}`;
    },
    async reloadSlots() {
      this.isLoading = true;
      await this.fetchSlotsBySchedules({ scheduleIds: this.selectedSchedulesIds });
      this.isLoading = false;
    },
    toScheduleName(item) {
      const start = this.$moment(item.start);
      const end = this.$moment(item.end);
      return `${start.isValid() ? start.format(timeDisplayFormat) : ""} - ${
        end.isValid() ? end.format(timeDisplayFormat) : ""
      }`;
    },
    add() {
      this.clearForm({});
      this.editDialog = true;
    },
    editSchedule(item) {
      this.setForm(item);
      this.editDialog = true;
    },
    addSlot() {
      this.setSlotForm(defaultFormSlot);
      this.toggleEditDialogSlot(true);
    },
    edit(item) {
      this.clearForm(item);
      this.$router.push({
        name: routesEnum.addOrganization,
        params: { id: item.id },
      });
    },
    onMoreClick(e) {
      console.log("on more click", e);
    },
    onCalendarDateClick(e) {
      console.log("on onCalendarDateClick", e);
    },
    changeCalendarValue(e) {
      this.calendarValue = e;
    },
    getEventColor(event) {
      console.log(event);
      switch (event?.slot?.appointmentTypeId) {
        case SlotAppointmentTypeEnum.EMERGENCY:
          return "#f2994a";
        case SlotAppointmentTypeEnum.FOLLOWUP:
          return "#673ab7";
        case SlotAppointmentTypeEnum.WALKIN:
          return "blue";
        case SlotAppointmentTypeEnum.ROUTINE:
          return "green";
        case SlotAppointmentTypeEnum.CHECKUP:
          return "#fb8c00";
      }
      return "blue";
      // return event.color;
    },
    selectCalendarTime(value) {
      console.log(value);
    },
    selectCalendarEvent(value) {
      console.log(value);
      let slot = value.event.slot;
      let start = this.$moment(slot.start);
      let end = this.$moment(slot.end);
      this.setSlotForm({
        ...slot,
        date: start.isValid() ? start.format("YYYY-MM-DD") : "",
        start: start.format("HH:mm"),
        end: end.format("HH:mm"),
      });
      this.toggleEditDialogSlot(true);
    },
  },
  async mounted() {
    scrollToTop();
    this.isLoading = true;
    const item = await OrganizationsApi.find(this.$route.params.organizationId);
    this.setOrganization(item);
    this.fetchOrganizationSchedules(this.$route.params.organizationId);
    this.isLoading = false;
    this.ready = true;
  },
};
</script>

<style lang="scss" scoped>
.organization-item-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;

  :deep .switcher {
    label {
      padding-left: 10px;
    }
  }
}

.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
</style>
