import { CancelTokenSource } from "axios";

import { IOrganization } from "@/pinia-store/organization";

import { apiRequest } from "./apiRequest";

const segment = "/organizations";

export interface AxisFormData extends FormData {
  file: File;
  patientId: string;
  component?: string;
  componentId?: string;
}

const create = async (body: Omit<IOrganization, "id">): Promise<any> => {
  const { data } = await apiRequest.post(segment, body);
  return data;
};

const update = async (body: Partial<IOrganization>): Promise<any> => {
  const { data } = await apiRequest.put(`${segment}/${body.id}`, body);
  return data;
};

const find = async (id: string): Promise<any> => {
  const { data } = await apiRequest.get(`${segment}/${id}`);
  return data;
};

const getAll = async (filter: Record<any, any> = {}, cancelToken?: CancelTokenSource): Promise<any> => {
  const { data } = await apiRequest
    .get(segment, {
      params: filter,
      ...(cancelToken ? { cancelToken: cancelToken.token } : {}),
    })
    .catch(() => ({ data: [] }));
  return data;
};
const destroy = async (fileId: string): Promise<any> => {
  const { data } = await apiRequest.delete(`${segment}/${fileId}`);
  return data;
};

export const OrganizationsApi = {
  getAll,
  destroy,
  create,
  find,
  update,
};
